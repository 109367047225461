<template>
  <div class="contributor-edit">
    <v-tabs
      v-model="tabs"
      fixed-tabs
    >
      <!-- Public Tab -->
      <j-tab
        :to="{ name: 'contributor-edit-public' }"
        icon="user"
      >
        Profile
      </j-tab>
  
      <!-- Editorial Tab -->
      <j-tab
        :to="{ name: 'contributor-edit-editorial' }"
        icon="edit"
      >
        Editorial
      </j-tab>
  
      <!-- Mailbox Tab -->
      <j-tab
        v-if="contact.mailbox" 
        :to="{ name: 'contributor-edit-mailbox' }"
        icon="envelope"
      >
        Mailbox
      </j-tab>
  
      
    </v-tabs>
    <!-- Router View -->
    <router-view />
  </div>
</template>

<script>

// Vuex
import { createNamespacedHelpers } from 'vuex'
const { mapState, mapActions, mapMutations } = createNamespacedHelpers('staff')

export default {
  name: 'StaffEditTabs',
  // -------
  // Data ==
  // -------
  data () {
    return {
      tabs: 'public'
    }
  },
  // -----------
  // Computed ==
  // -----------
  computed: {
    ...mapState([
      'contact'
    ]),
    formTitle() {
      return this.contact.isNew() ? '' : 'Edit Profile'
    }
  },
  // ----------
  // Methods ==
  // ----------
  methods: {
    ...mapActions([
    ]),
    ...mapMutations([
      'RESET_CONTACT',
      'SHOW_CHANGE_PASSWORD',
      'SHOW_DISABLE_CONTACT_CONFIRMATION'
    ]),
    handleShowChangePassword() {
      this.SHOW_CHANGE_PASSWORD()
    },
    handleReset() {
      this.RESET_CONTACT()
    },
    handleDisable() {
      this.SHOW_DISABLE_CONTACT_CONFIRMATION()
    }
  }
}
</script>
